@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f4;
  font-size: 16px;
}

a:focus,
button:focus {
  outline: none !important;
}

textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: auto;
  resize: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  /* font-family: 'Oswald', sans-serif; */
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 800;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

em {
  font-style: italic;
}

hr {
  opacity: 0.25;
  margin: 1em 0 2em;
}

.form-group {
  margin-bottom: 10px;
}

.uppercase {
  .dropdown-component-container {
    div {
      text-transform: uppercase !important;
    }
  }
}

input,
.form-select {
  width: 100%;
  padding: 2.5px 7.5px 2.5px;
  margin: 0 0 5px;
  border: 2px solid #ddd;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  color: inherit;
  font-size: 0.9rem;
  outline: 0;
  padding: 2.5px 7.5px 2.5px;
  vertical-align: middle;
  width: 100%;

  &::after {
    background: url(http://cdn.onlinewebfonts.com/svg/img_424131.png);
  }
}

.form-select:not([multiple]):not([size]) {
  background: #fff url(http://cdn.onlinewebfonts.com/svg/img_424131.png)
    no-repeat right 0.35rem center/.4rem 0.5rem;
  padding-right: 1.2rem;
}

label {
  display: block !important;
}

.ml {
  margin-left: 15px;
}

.white {
  color: white !important;
}

.gray {
  color: #eee;
}

h1 {
  font-size: 1.75em;
  margin-bottom: 10px !important;

  span {
    cursor: pointer;
  }

  button {
    font-size: 0.55em !important;
    margin-bottom: 5px;
  }

  .smaller {
    font-size: 0.6em;
  }

  span.small {
    font-size: 0.5em !important;
    padding: 7.5px 12.5px;
    background-color: #ddd;
    border-radius: 5px;
    vertical-align: middle;
    color: #666;

    &:hover {
      color: #333 !important;
    }

    i {
      margin-right: 2.5px;
      vertical-align: middle;
    }
  }
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
  cursor: pointer;
}

a {
  color: #000;

  &:hover {
    /* filter: brightness(105%); */
    color: #000;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  display: none !important;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.faded {
  opacity: 0.5;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.full-width {
    .form-group {
      width: 98%;
      margin-right: 1%;
      max-width: 400px;

      &:last-child {
        margin-right: 0;
        margin-left: 1%;
      }
    }
  }
}

.primary {
  color: dodgerblue !important;
}

.info {
  color: #17a2b8 !important;
}

.bg-gray {
  background-color: #eee !important;
}

button,
.button {
  border: none;
  background: none;
  background-color: dodgerblue;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase !important;
  /* box-shadow: 0 1px 3px 0 rgba(0,0,0,0.06); */
  border-radius: 5px;
  padding: 7.5px 15px;
  letter-spacing: 0.5px;
  &:hover {
    filter: brightness(98%);
  }
}

.btn-outline {
  //border: 2px solid #eee;
  background-color: #eee;
  border-radius: 5px;
  padding: 8px;
  font-size: 0.7em;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  color: #333;
  letter-spacing: 0;
  width: 100%;

  i {
    &.ion-ios-close {
      margin: 0 !important;
    }
  }

  &.super-submit {
    font-size: 1.25em;
    border-width: 2px;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -15px;
  }

  .ion-arrow-down-b {
    font-size: 12px;
    margin-left: 5px;
  }
}

.blurred-background {
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(400px);
  z-index: -1;
}

table {
  .dropdown-component {
    width: 100%;
    .dropdown-component-container {
      left: 0;
      right: 0;
      top: 30px;
      min-width: 0;
      z-index: 1;
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }
}

.placeholder {
  padding: 1em 15px;
  text-align: center;

  i {
    font-size: 3em;
    line-height: 1.25;
  }

  h3 {
    font-weight: 400;
  }

  button {
    margin: 1em auto;
  }
}

.custom-file-input::-webkit-file-upload-button {
  font-size: 0.8em;
  margin: 3px 0;
  font-weight: 800;
  letter-spacing: 0.25px;
}

/* .contain-loading {
  padding: 10px;
  position: relative !important;

  .spinner {
    &:before {
      content: '';
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      top: 57% !important;
      border-radius: 50%;
      border: 5px solid #ccc;
      border-top-color: dodgerblue;
      animation: spinner 0.6s linear infinite;
    }
  }
} */
.transporter-container {
  &.modal {
    .modal-body {
      min-height: 325px;
      position: relative;

      .form-group {
        margin-bottom: 20px;
      }
    }

    .full-loading {
      background-color: rgba(255, 255, 255, 0.5);
      height: 495px;
      font-weight: 800;
    }
  }
}
.full-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  z-index: 99;

  &.submitted {
    left: 0;
    right: 0;
    margin: auto;

    h3 {
      top: 50vh;
      position: fixed;
    }

    .spinner {
      top: 40vh;
      position: fixed;
      z-index: 10;
      left: 0;
      right: 0;
      margin: auto;
      padding: 20px;

      img {
        height: 100px;
        position: absolute;
        left: 50px;
        right: 0;
        margin: auto;
      }

      &:before {
        content: '';
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid #ccc;
        border-top-color: dodgerblue;
        animation: spinner 0.6s linear infinite;
      }
    }
  }

  .spinner:before {
    top: calc(50% - 20px);
  }

  h3 {
    margin-top: 80px;
  }
}

.alert {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 3px;
  z-index: 10;
  min-width: 380px;
  font-weight: 600;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer !important;

  i {
    font-size: 1.5em;
    vertical-align: middle;
    margin: 0 5px;

    &.ion-android-close {
      font-size: 1.25em;
    }
  }

  &.success {
    background-color: rgb(41, 184, 41);
    color: #fff;
  }

  &.error {
    background-color: rgb(255, 0, 89);
    color: #fff;
  }
}

.header {
  padding: 1em;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  font-weight: 600;
  font-size: 1.25em;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #0290ff;
  z-index: 5;

  .logo {
    height: 30px;
    width: auto;
  }

  .left,
  .right {
    min-width: 200px;
  }

  div {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.left {
      justify-content: flex-start;
    }
  }

  .avatar-container {
    background-color: rgba(255, 255, 255, 0.25);
    height: 45px;
    width: 45px;
    border-radius: 30px;
    background-size: cover;
    margin-left: 2.5px;
    justify-content: center;
    color: #fff;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

  .ion-arrow-down-b {
    margin-left: 2.5px;
    color: rgba(255, 255, 255, 0.8);
  }

  .ion-chevron-left {
    margin-top: -7px;
  }

  .ion-ios-people,
  .ion-ios-locked {
    margin: 0 0 0 7.5px;
    color: rgba(0, 0, 0, 0.8);
  }

  img {
    height: 50px;
    width: auto;

    &.avatar {
      height: 45px;
      width: 45px;
      border-radius: 30px;
      margin: 0;
      background-size: cover;
      //border: 5px solid #b620e0;
    }
  }

  .search-btn {
    margin-right: 15px;
  }
  .search-btn,
  .nav-btn {
    border: 1px solid transparent;
    font-size: 0.9em;
    padding: 5px 15px 5px;
    background-color: #0074e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    height: 37px;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
    /* margin: 0 20px; */
    // opacity: 0.8;
    white-space: nowrap;

    &:hover {
      opacity: 1;
    }

    .search-container {
      display: flex;
      color: #fff;
    }

    &:hover,
    &:focus {
      border: 1px solid #0074e1;
      background-color: #0074e1;
    }

    input {
      margin-left: 5px;
      border: none;
      background: transparent;
      min-width: 200px;
      color: #fff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &:hover,
      &:focus {
        background-color: #0074e1;
      }
    }

    .ion-ios-close,
    .ion-search {
      color: rgba(255, 255, 255, 0.5) !important;
    }
    .ion-ios-close {
      &:hover {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }

  .nav-btn {
    border: 1px solid transparent;
    background-color: transparent;
    /* width: 175px; */
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: rgba(255, 255, 255, 1);
      background-color: transparent;
      border: 1px solid transparent;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 4;

  .ion-arrow-down-b {
    font-size: 12px;
    margin-left: 5px;
  }

  .ion-chevron-left {
    margin-right: 10px;
  }

  .nav-drawer {
    position: absolute;
    z-index: 1;
    top: 0;
    padding-top: 60px;
    right: 80px;
    width: 220px;
    z-index: 4;
    flex-direction: column;
    justify-content: flex-start;

    &.avatar {
      right: 0;
      width: 160px;
    }

    .nav-option {
      padding: 5px 10px;
      border: 1px solid #eee;
      border-top: 0;
      display: block;
      color: #000;
      font-weight: 600;
      cursor: pointer;
      background-color: #fff !important;
      width: 100%;

      img {
        height: 30px;
        width: 30px;
        margin-right: 7.5px;
      }

      &:hover {
        color: black;
        text-decoration: none;
        background-color: #fafafa !important;
      }
    }
  }
}

.admin {
  &.search-results {
    right: 440px;
  }
}

:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-results {
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.05);
  color: #222;
  position: absolute;
  top: 60px;
  right: 240px;
  width: 100%;
  max-width: 300px;
  margin: auto;
  display: flex;
  z-index: 4;
  flex-direction: column;
  overflow: scroll;
  max-height: 80vh;
  //padding: 5px;
  border-radius: 0 0 5px 5px;

  img {
    height: 30px;
    width: 30px;
  }

  table {
    width: 100%;
    font-weight: 600;

    tr {
      cursor: pointer;

      &:hover {
        color: black;
      }
    }

    td {
      padding: 5px 10px;
      border-top: 1px solid #eee;

      &:first-child {
        width: 40px;
        //border-top: none;
      }

      .title {
        line-height: 1;
      }

      .background-image {
        background-size: cover;
        height: 50px !important;
        width: 32px !important;
        min-width: 32px !important;
        border-radius: 3px !important;
      }
    }
  }
}

.landing {
  background-color: #0290ff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  position: absolute;
  z-index: 9;

  button {
    width: 100%;
    text-align: center;
    display: block;
  }

  a {
    font-weight: 600;
  }

  .text-container {
    margin: 2em auto 3em;
  }

  .overlay {
    background-color: rgba(2, 144, 255, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }

  .placeholder-container {
    text-align: center;
  }

  .container {
    width: 400px;

    input {
      border: none !important;
    }

    h3 {
      text-align: left;
      margin-top: 1em;
      font-size: 1.25em;
      margin-bottom: 0.5em;
    }

    .card {
      margin-top: 10px;
      padding: 10px 15px !important;
      background-color: rgba(255, 255, 255, 0.25);
    }

    .container-img {
      text-align: center;
      img {
        margin-top: 1em;
        height: 50px;
        width: auto;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 5px 0;
        a {
          color: rgba(255, 255, 255, 1);
          &:hover {
            color: rgba(0, 0, 0, 0.6);
          }

          img {
            height: 30px;
            width: 30px;
            margin-right: 7.5px;
          }

          /* i {
            margin-right: 5px;
          } */
        }
      }
    }
  }
}

.tab-group {
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 800;
  font-size: 1.25em;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;

  i {
    margin-right: 2.5px;
  }

  div {
    width: 150px;
    align-self: center;
    text-align: center;
    cursor: pointer;
    padding: 5px 5px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.4);

    &.active {
      border-bottom: 5px solid dodgerblue;
      color: #333;
    }
    &:hover {
      border-bottom: 5px solid dodgerblue;
      color: #333;
    }
  }
}

.container {
  padding: 15px 25px;
  max-width: 1200px;
  margin: auto;

  .modal {
    .search-btn {
      font-size: 1em;
      margin-bottom: 15px;
      background-color: #eee;
      input {
        font-size: 1em;
      }

      .ion-ios-close,
      .ion-search {
        font-size: 1em;
      }
    }
  }

  .search-btn {
    border: 1px solid transparent;
    font-size: 0.9em;
    padding: 5px 15px 5px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    height: 37px;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 5px;

    &:hover {
      opacity: 1;
    }

    .search-container {
      display: flex;
      color: #222;
    }

    /* &:hover,
    &:focus {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background-color: rgba(255, 255, 255, 0.35);
    } */

    input {
      margin-left: 0;
      border: none;
      background: transparent;
      min-width: 250px;
      font-size: 0.75em;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    .ion-ios-close,
    .ion-search {
      color: rgba(0, 0, 0, 0.5) !important;
      font-size: 0.75em;
    }
    .ion-ios-close {
      &:hover {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }
  }

  &.projects,
  &.show-project,
  &.users {
    padding-top: 75px;
  }
}

.success {
  color: rgb(41, 184, 41);
}

.btn-success {
  background-color: rgb(41, 184, 41) !important;
  color: #fff !important;
}

.btn-danger {
  background-color: rgb(255, 0, 89) !important;
  color: #fff !important;
}

.btn-secondary {
  background-color: #eee;
  color: #333 !important;
}

.btn-transparent {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  font-size: 0.9em !important;
  color: dodgerblue !important;
  .ion-arrow-down-a {
    margin-right: 2px !important;
  }
  &:hover {
    color: rgb(26, 123, 219) !important;
  }
}

.warning {
  color: #ffae00;
}

.danger {
  color: rgb(255, 0, 89);
}

.small,
small {
  font-size: 0.75em;
}

.large {
  font-size: 1.25em;
}

.card {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  /* overflow: hidden; */

  .card-header {
    padding: 0;

    .dropdown {
      text-transform: uppercase !important;
    }

    .container-super-submit {
      display: flex;
      flex-direction: column;
      min-width: 272px;
      padding: 10px 10px 5px;
      background-color: #fafafa;
      border-radius: 5px 5px 0 0;
      border: 2px solid #eee;
      border-bottom: none;

      small {
        font-weight: 600;
        text-align: center;
      }
      .super-submit {
        width: 100%;
        margin-bottom: 0 !important;
        display: block;

        span {
          color: #555;
          small {
            opacity: 0.75;
          }
        }
      }
    }

    .d-flex {
      min-height: 60px;
      align-items: baseline;
    }

    button {
      font-size: 16px;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-content: center;

      .ion-arrow-down-a {
        margin-right: 5px;
      }

      .ion-arrow-down-b {
        margin-left: 5px;
      }

      /* i {
        margin-left: 5px;
      } */

      &.super-submit {
        color: white;

        &:disabled {
          cursor: not-allowed;
          background-color: #ccc !important;
        }
      }
    }
  }
}

.footer {
  max-width: 600px;
  margin: auto;
  padding: 0px 10px 35px;
  /* border-top: 1px solid #eee; */
  text-align: center;
  font-size: 0.75em;

  .d-flex {
    justify-content: center;
    margin-top: 10px;

    a {
      padding: 0 10px;
    }
  }

  i {
    margin-right: 5px;
  }

  a {
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.dropdown-component-container {
  position: absolute;
  right: 20px;
  min-width: 200px;
  background-color: #fff;
  font-size: 16px;
  border: 2px solid #eee;
  z-index: 2;

  div {
    padding: 7.5px 10px;
    border-bottom: 1px solid #eee;
    display: block;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    background-color: #fff;
    text-transform: capitalize;

    &:hover {
      background-color: #fafafa !important;
    }
  }
}

.dropdown-component {
  position: relative;
  .btn-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    min-width: 200px;
    position: absolute;
    right: 0;
    top: -30px;
    z-index: 2;
  }
  .dropdown-component-container {
    right: 0;
    top: 40px;
    min-width: 200px;
    z-index: 1;
  }
}

.card-header {
  .dropdown-component {
    .dropdown-component-container {
      top: 8px;

      &.overflow-container {
        top: 36px;

        a {
          text-transform: capitalize;
        }
      }
    }
  }
}

h1 {
  .dropdown-component {
    .btn-dropdown {
      z-index: 4;
    }
    .dropdown-component-container {
      z-index: 3;
    }
  }
}

.dropdown-container {
  position: relative;
  min-width: 250px;

  button {
    min-width: 250px;
    z-index: 3;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eee;
    font-size: 16px;
  }

  .dropdown {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    border: 2px solid #eee;
    z-index: 1;

    span {
      padding: 7.5px 10px;
      border-bottom: 1px solid #eee;
      display: block;
      color: #000;
      font-weight: 600;
      cursor: pointer;
      background-color: #fff;

      &:hover {
        color: black;
        text-decoration: none;
        background-color: #fdfdfd;
      }
    }
  }
}

.settings-table {
  button {
    font-size: 0.6em;
    height: 25px;
    width: 25px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    margin: 0 5px;
  }
}

.table {
  margin-bottom: 2em;
  display: table;
  width: 100%;

  .table-row {
    display: table-row;
    width: auto;
    clear: both;
    cursor: pointer;

    &.table-header {
      .table-cell {
        text-transform: uppercase !important;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }

    &.table-header {
      font-weight: 800;

      .table-cell {
        border-top: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .table-cell {
      float: left;
      display: table-column;
      width: calc(100% / 3);
      padding: 5px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      text-transform: uppercase;

      &:first-child {
        text-transform: capitalize;
      }

      .status {
        font-weight: 600;
      }

      .faded {
        margin-left: 5px;
      }

      a {
        color: #212529;
      }
    }
  }
}

/* Users */
.users {
  .table {
    margin-bottom: 2em;
    display: table;
    width: 100%;

    .table-row {
      .table-cell {
        width: calc((100% - 500px) / 2);
        text-transform: capitalize;

        &:first-child {
          width: 400px;
          text-transform: initial;
        }

        &:last-child {
          width: 100px;
          text-align: center;
        }
      }
    }
  }
}

/* Projects */
.filename {
  word-break: break-all;
}

.projects {
  .card-header {
    h2 {
      i {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }

  .card-header {
    margin-bottom: 1em;
  }

  .table {
    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
    .table-header {
      font-weight: 800;
      font-size: 0.75em;
      text-transform: uppercase;
      opacity: 0.75;
      letter-spacing: 0.5px;

      i {
        font-size: 0.75em;
        margin-left: 5px;
      }

      .table-cell {
        padding: 5px;
        min-height: 25px;

        img {
          height: 10px;
          margin-left: 5px;
          width: auto;
          opacity: 0.5;
        }
      }
    }

    .table-cell {
      min-height: 50px;
      align-items: center;
      display: flex;
    }

    i {
      font-size: 1.25em;
      vertical-align: middle;
      line-height: 1.25;

      &.ion-record {
        opacity: 1;
      }
    }
  }
}

/* Show Project */
.show-project {
  .ion-arrow-down-a {
    margin-right: 5px;
  }

  .overflow-btn {
    background-color: rgba(0, 0, 0, 0.05);
    height: 35px;
    width: 35px;
    border-radius: 20px;
    margin-left: 10px;
    margin-bottom: 0;

    i {
      font-size: 1.5em !important;
      color: #333;
      line-height: 0;
      margin-top: -2px;
      margin-left: 1px;
    }
  }

  &.tab1,
  &.tab2 {
    .card {
      table {
        border: 2px solid #eee;
        tr {
          &.last-child {
            td {
              &.last-child {
                border-bottom: 2px solid #eee;
              }
            }
          }
        }
        th {
          &:last-child,
          &:nth-child(4) {
            //background-color: #eee;
            border-top: 1px solid #eee;
            //border-left: 1px solid #eee;
            background-color: #fafafa;
          }
          &:last-child {
            border-right: 2px solid #eee;
          }
        }
        th {
          &:nth-child(3) {
            border-right: 2px solid #eee;
          }
        }
        td {
          &:last-child {
            border-right: 2px solid #eee;
            background-color: #fafafa;
          }
          &.td-section {
            border-left: 2px solid #eee;
            background-color: #fafafa;
          }
        }
      }
    }
  }

  .card {
    min-width: 1130px;
  }

  h1 {
    background-color: transparent;
    padding: 0;

    .smaller {
      display: block;
    }

    img {
      height: 30px;
      width: 30px;
      vertical-align: -5px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }

    span,
    small {
      cursor: pointer;
    }

    span {
      margin-right: 5px;
    }

    i {
      font-size: 0.75em !important;
      line-height: 0;
      vertical-align: top;
      margin: 0;

      &.ion-record {
        cursor: help;
      }

      &.ion-ios-arrow-left,
      &.ion-chevron-left {
        font-size: 0.75em !important;
        margin-left: 5px;
        vertical-align: 2px;
      }
    }

    /* .overflow-btn {
      background-color: #ddd;
      height: 35px;
      width: 35px;
      border-radius: 20px;
      margin-left: 10px;
      margin-bottom: 0;

      i {
        font-size: 1.5em !important;
        color: #333;
        margin: 0;
        padding: 0;
      }
    } */

    .ion-social-android {
      vertical-align: 10px;
    }

    .ion-record {
      font-size: 0.5em !important;
      vertical-align: 13px;
    }
  }

  table {
    /* margin-top: 2em; */
    width: 100%;
    border: 1px solid #eee;

    th {
      font-weight: 800;
      font-size: 0.75em;
      text-transform: uppercase;
      opacity: 0.75;
      letter-spacing: 0.5px;
    }
    th,
    td {
      padding: 5px 10px;
      vertical-align: middle;

      .ion-record {
        &.faded {
          opacity: 0.25;
        }
      }

      &:first-child {
        width: 220px;
        white-space: nowrap;
      }

      &:last-child {
        /* background-color: #eee; */
        width: 120px;
        min-width: 120px;
        .btn-rounded.small {
          font-size: 11px;
          padding: 4px 10px;
          line-height: 1.4;
        }
        /* i {
          margin-right: 5px;
          &.faded {
            opacity: 0.25;
          }
        } */
      }
      &:nth-child(2) {
        width: 300px;
      }
      &:nth-child(4) {
        /* background-color: #eee; */
        width: 150px;
        min-width: 150px;
      }
    }
    td {
      border-top: 1px solid #eee;
      position: relative;

      .disabled-row {
        background-color: rgba(255, 255, 255, 0.65);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        cursor: not-allowed;
        z-index: 1;
      }

      small {
        display: block;
      }

      i {
        font-size: 1.25em;
        vertical-align: -1px;
        margin-left: 0;
        line-height: 0;

        &.ion-record {
          font-size: 0.75em;
          vertical-align: 1px;
        }
      }
      .ion-ios-close {
        margin-left: 5px;
      }
      button {
        margin-left: 0;
      }
      .expedited {
        line-height: 1.25;
        cursor: help;
      }
    }
  }
  .upload-actions {
    min-width: 110px;
  }
  &.tab3 {
    .card {
      .card-header {
        h2 {
          margin-bottom: 10px;
          align-content: center;
        }
      }
    }
  }
}

.input-container {
  cursor: pointer;
  b {
    margin: 0 2px;
  }
  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  div {
    background-color: #f8f8f8;
    font-size: 0.8em;
    line-height: 1.2;
    padding: 10px 10px;
    border-radius: 5px;
    width: 260px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);

    span:first-child {
      max-width: 140px;
      overflow-wrap: break-word;
      padding-right: 2.5px;
    }

    button {
      border-radius: 15px;
      margin-left: 5px;
      background-color: #eee;
      color: dodgerblue;

      &:hover {
        color: #fff;
        background-color: dodgerblue;
      }
    }
  }
}

/* Notes */
.notes-container {
  max-width: 600px;
  margin: auto;
  position: relative;

  h3 {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
  button {
    margin-top: -1px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 0 0 5px 5px;
    z-index: 1;
    position: absolute;
  }

  .spinner-btn {
    .spinner {
      &:before {
        position: absolute;
        top: 10px;
      }
    }
  }

  .chat-bubble {
    background-color: #fefefe;
    padding: 5px 15px;
    border-radius: 10px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .chat-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

/* Modal */
.modal {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    min-height: 200px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    padding-bottom: 40px;
    max-height: 80vh;
    overflow: hidden;

    .aws-steps {
      text-align: center;
      margin: 20px auto 0;

      .button {
        margin: 5px auto;
      }
    }

    .modal-header {
      padding: 10px 15px;
      h2 {
        margin-bottom: 0;
      }
    }

    .modal-body {
      padding: 15px;
      overflow: scroll;
      max-height: 68vh;

      .table {
        .table-row {
          .table-cell {
            width: 90%;
            &:first-child {
              width: 10%;
              padding-left: 2.5%;
              padding-right: 2.5%;
            }
          }
        }
      }
    }

    .modal-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 15px 15px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        min-width: 150px;
        margin-left: 15px;
        min-width: 120px;
        font-size: 15px;
      }
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;

      i {
        cursor: pointer;
      }
    }
  }
}

.collapse-component {
  a {
    color: initial;
  }
  .collapse-header {
    border-top: 1px solid #eee;
    padding: 5px;
    cursor: pointer;

    &.d-flex {
      justify-content: flex-start;
      i {
        margin-right: 5px;
      }
    }

    h3 {
      margin-bottom: 0;
    }
  }
  .collapse-body {
    margin: 0.5em 1em 1em;
    .table-header {
      div {
        font-weight: 600;
      }
    }
    .table-row {
      padding: 5px 10px;
      justify-content: flex-start;
      border: 1px solid #eee;

      /* .collapse-component .collapse-body .table-row div:nth-child(2) */

      .table-value {
        .show-all {
          div {
            width: 100% !important;
          }
        }
        .show-snippet {
          max-height: 40px;
          overflow: hidden;
          word-break: break-all;
          small {
            display: block;
            font-weight: 600;
          }
        }

        button {
          font-size: 10px;
          padding: 2.5px 7.5px;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }

      div {
        overflow: hidden;
        padding: 5px;

        p {
          line-height: 1.3;
          padding-right: 5px;
          small {
            display: block;
          }
        }

        &:first-child {
          width: 200px;
          margin-right: 10px;
        }
        &:nth-child(2) {
          width: calc(100% - 600px);
        }
        &:nth-child(3) {
          width: 250px;
        }
        &:last-child {
          width: 150px;
        }
      }

      &:nth-child(even) {
        background-color: #f8f8f8;
      }
    }
  }
}

.wysiwyg-container {
  border: 2px solid #eee;

  div {
    background-color: #fff !important;
    border: none !important;
    &:first-child {
      border-bottom: 1px solid #eee !important;
    }
  }

  [contenteditable] {
    padding: 5px 10px !important;
    border: none !important;

    div:first-child {
      border: none !important;
    }
  }

  button {
    margin: 0 5px;
    height: 40px !important;
    width: 40px !important;
  }
  span {
    width: 10px !important;
    background-color: #fff !important;
    vertical-align: middle;
  }

  select {
    margin-left: 10px;
    border-radius: 5px;
    width: 200px;
    display: none !important;
    margin: 0 5px;
    padding: 0 5px;
    height: 25px !important;
    line-height: 1;
  }
}
